<script setup>
import {Link, useForm, usePage} from '@inertiajs/vue3';
import Checkbox from '@/Components/Checkbox.vue';
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import PrimaryButton from '@/Components/Buttons/PrimaryButton.vue';
import Socialstream from '@/Components/Socialstream.vue';
import TextInput from '@/Components/TextInput.vue';
import AppLayout from "@/Layouts/AppLayout.vue";
import Card from "@/Components/Card.vue";
import {route} from "ziggy-js";
import AkLogo from "@/Components/Icons/AkLogo.vue";
import PasswordInput from "@/Components/PasswordInput.vue";
import LoginRegisterButton from "@/Pages/Auth/LoginRegisterButton.vue";
import RecaptchaBranding from "@/Pages/Auth/recaptcha-branding.vue";
import {inject} from "vue";
import {useGoogleRecaptcha} from "@/plugins/recaptcha.js";

defineProps({
    canResetPassword: Boolean,
    status: String,
});

const form = useForm({
    email: '',
    password: '',
    remember: false,
    g_captcha_response: ''
});
const updateChannel = inject('updateChannel'),
    updateMessageChannel = inject('updateMessageChannel')

const {getRecaptchaToken} = useGoogleRecaptcha()
const submit = async () => {
    form.g_captcha_response = await getRecaptchaToken()
    form.transform(data => ({
        ...data,
        remember: form.remember ? 'on' : '',
    })).post(route('login'), {
        onFinish: () => {
            form.reset('password')
            updateChannel(usePage().props.auth.user)
            updateMessageChannel(usePage().props.auth.user)
        },
    });
};
</script>

<template>
    <AppLayout :title="$t('Login')">
        <Card class="flex shrink justify-center max-w-[340px] md:max-w-[680px] mx-auto overflow-hidden">
            <div class="px-3 py-5 w-full max-w-[340px]">
                <LoginRegisterButton type="login"/>
                <div>
                    <div v-if="status" class="mb-4 font-medium text-sm text-green-600">
                        {{ status }}
                    </div>

                    <form class="py-3" @submit.prevent="submit">
                        <div>
                            <InputLabel class="px-2" for="email" value="Email"/>
                            <TextInput
                                id="email"
                                v-model="form.email"
                                autofocus
                                class="mt-3 block w-full"
                                :placeholder="$t('Enter email')"
                                required
                                type="email"
                            />
                            <InputError :message="form.errors.email" class="mt-2"/>
                        </div>

                        <div class="mt-4">
                            <InputLabel class="px-2" for="password" :value="$t('Password')"/>
                            <PasswordInput
                                id="password"
                                v-model="form.password"
                                autocomplete="current-password"
                                class="mt-3 block w-full"
                                :placeholder="$t('Enter password')"
                                required
                            />
                            <InputError :message="form.errors.password" class="mt-2"/>
                            <InputError v-if="form.errors.g_captcha_response" class="mt-2"
                                        :message="$t('Verification failed, try again')"/>
                        </div>

                        <div class="block mt-4">
                            <label class="flex items-center">
                                <Checkbox v-model:checked="form.remember" name="remember">
                                    <template #label>
                                        <span class="text-sm text-gray-600">{{ $t('Remember me') }}</span>
                                    </template>
                                </Checkbox>
                            </label>
                        </div>

                        <div class="flex items-center justify-between mt-4">

                            <Link v-if="canResetPassword" :href="route('password.request')"
                                  class="underline text-sm dark:text-slate-200
                rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600
                font-medium ml-2">
                                {{ $t('Forgot password?') }}
                            </Link>
                            <PrimaryButton :class="{ 'opacity-25': form.processing }" :disabled="form.processing"
                                           class=" uppercase">
                                {{ $t('Login') }}
                            </PrimaryButton>
                        </div>
                    </form>
                </div>
                <Socialstream v-if="$page.props.socialstream.show"/>
                <RecaptchaBranding/>
            </div>
            <div class="hidden w-full max-w-[340px] md:flex justify-center items-center bg-slate-50 dark:bg-dark-blue">
                <AkLogo class="w-[220px] h-[256px]"></AkLogo>
            </div>
        </Card>
    </AppLayout>
</template>
